<script lang="ts" setup>
const search = defineModel<string | null | undefined>('search')
const filter = defineModel<string | null | undefined>('filter')
</script>
<template>
  <div
    class="import-search flex flex-col items-center gap-2 border-b-2 border-gray-800 pb-4 md:flex-row"
  >
    <slot name="search" :search="search">
      <FormKit
        v-model="search"
        type="search"
        name="search"
        placeholder="Search your lease..."
        prefix-icon="search"
        prefix-icon-class="!bg-transparent to-transparent !border-0"
        outer-class="w-full mr-5 "
        inner-class="border-gray-800/20 !bg-gray-550/5 !mb-0 hover:!bg-gray-800"
        @input="$emit('search', $event as string | null | undefined)"
      />
    </slot>
    <slot name="filter" :filter="filter">
      <FormKit
        v-model="filter"
        type="listbox"
        name="filter"
        wrapper-class="
        flex items-center border-2 border-gray-750 rounded-xl bg-gray-800
        before:content-['Filter'] before:text-gray-400 before:text-sm before:font-semibold before:mx-2"
        prefix-icon-class="!bg-transparent to-transparent !border-0"
        outer-class="min-w-[220px]"
        inner-class="flex-grow !mb-0"
        :options="[]"
        @input="$emit('filter', $event as string | null | undefined)"
      />
    </slot>
  </div>
</template>

<style lang="postcss" scoped>
.import-search :deep(.multiselect) {
  @apply rounded-lg;
  .multiselect__tags {
    @apply bg-gray-850;
  }

  .multiselect__select {
    @apply bg-gray-850;
  }
}
</style>
